var datePickerStartDate,
  datePickerEndDate,
  selectedReservation,
  selectedResourceId,
  previousResourceId;

function renderForm(resources, filterLevels) {
  var selects = filterLevels.map((filter) => {
    var resourceType = filter.id,
      options = resources[resourceType];

    delete resources[resourceType];

    return {
      name: filter.name,
      id: filter.id,
      options: Object.values(options).sort((a, b) => {
        if(a.name < b.name) { return -1; }
        if(a.name > b.name) { return 1; }
        return 0;
      })
    }
  });

  var bookables = Object
    .values(resources)
    .reduce((output, resourceType) => {
      Object
        .values(resourceType)
        .filter((resource) => resource.bookable)
        .sort((a, b) => a.Name < b.Name)
        .forEach((bookable_resource) => {
          var resourceTypeName = bookable_resource.resource_type_name;
          if (!output.options.hasOwnProperty(resourceTypeName))
            output.options[resourceTypeName] = [];

          output.options[resourceTypeName].push(bookable_resource)
        });

      return output
    }, {
      name: 'Resource',
      options: {}
    });


  if (Object.keys(bookables.options).length === 1) {
    bookables.name = Object.keys(bookables.options)[0];
    bookables.options = Object.values(bookables.options)[0];
  }

  selects.push(bookables);
  renderFields(selects)
}

function renderFields(fields) {
  let fieldsWrapper = document.querySelector('.dynamic-fields-js');

  function createElement(html) {
    let el = document.createElement('div');
    el.innerHTML = html;
    return el.firstElementChild;
  }

  fields.forEach((field, index) => {
    let fieldWrapper = createElement(`
      <div class="field mb-30 field-js">
        <label for="" class="field-label">
          ${field.name}
          <span class="asterisk">*</span>
        </label>
        <select data-options="${btoa(encodeURIComponent(JSON.stringify(field.options)))}" data-filterLevel="${index}" class="field-select location-detail-js validate-me-js"></select>
        <span class="field-error field-error-js">
          This field is required
        </span>
      </div>
    `);

    let select = fieldWrapper.querySelector('.field-select');
    select.addEventListener('change', (e) => {
      validateField(e.target);
      updateField(e.target.value, index + 1);
    });

    fieldsWrapper.appendChild(fieldWrapper);

    if (index === 0) {
      updateField(null, 0);
    }
  });
}

function renderOptions(options, filter_func, first_empty=true) {
  let renderOption = ({ Id, name }) => `<option value=${Id}>${name}</option>`;

  let options_output = !first_empty ? [] : [
    `<option></option>`,
  ];

  let rendered_options =
    Array.isArray(options)
    ? options
      .filter(filter_func)
      .map(renderOption)
    : Object
      .entries(options)
      .map(([name, options]) => `
        <optgroup label="${name}">
          ${renderOptions(options, filter_func, false)}
        </optgroup>
      `);

  return options_output
    .concat(rendered_options)
    .join('');
}

function updateField(value, filterLevel) {
  let select = document.querySelector(`[data-filterlevel="${filterLevel}"]`);

  if (select !== null) {
    let options = JSON.parse(decodeURIComponent(atob(select.getAttribute('data-options'))));
    select.innerHTML = renderOptions(
      options,
      filterLevel === 0
      ? () => true
      : (option) => option.parent === value
    );

    updateField(null, filterLevel + 1);
  } else if (value !== null) {
    selectedResourceId = value;
  }
}

function findAncestor(el, cls) {
  while ((el = el.parentElement) && !el.classList.contains(cls)) ;
  return el;
}

function confirmBooking() {
  document.body.classList.add('booking-confirmed');
}

function renderTimeSlots(data) {
  var container = document.querySelector('.available-times-js');
  container.innerHTML = '';
  var currentDay = datePickerStartDate;

  while (currentDay.valueOf() <= datePickerEndDate.valueOf()) {
    var day = document.createElement('div');
    day.classList.add('available-day');

    var reservations = data[currentDay.format('YYYY-MM-DD')];
    if (reservations !== undefined) {
      reservations.forEach((reservation) => {
        var timeslot = document.createElement('a');
        timeslot.classList.add('time');
        if (!reservation.available) {
          timeslot.classList.add('--unavailable');
        }
        timeslot.innerText = moment(reservation.start).format('HH:mm') + ' ' + moment(reservation.end).format('HH:mm');
        timeslot.onclick = () => setActive(timeslot, reservation);
        timeslot.dataset.reservation = reservation.reservation_id;
        day.appendChild(timeslot);
      })
    }
    container.appendChild(day);
    currentDay = moment(currentDay).add(1, 'days');
  }
}

function clearDate() {
  document.body.classList.add('date-removed');
  $('.ui-state-default').one('transitionend webkitTransitionEnd oTransitionEnd', (e) => {
    $('body').removeClass('date-selected date-removed');
  });
}

function closeDatepicker(e, force) {
  e.preventDefault();
  if (e.target.classList.contains('date-time-picker-wrapper') || force) {
    if (selectedReservation !== undefined) {
      var field = document.querySelector('.timeslot-input-js');
      field.value = moment(selectedReservation.start).format('lll')
        + ' - '
        + moment(selectedReservation.end).format('lll');
      validateField(field);
    }

    document.body.classList.remove('datepicker-open');
    document.body.classList.add('datepicker-closed');
  }
}

function updateInfo() {
  var locationDetailList = Array.from(document.querySelectorAll('.location-detail-js'));
  var personalDetailList = Array.from(document.querySelectorAll('.personal-detail-js'));

  var locationDetailsWrapper = document.querySelector('.location-details-js');
  locationDetailsWrapper.innerHTML = '';
  var personalDetailsWrapper = document.querySelector('.personal-details-js');
  personalDetailsWrapper.innerHTML = '';
  var dateInput = document.querySelector('.timeslot-input-js');

  locationDetailList.forEach((detail) => {
    var detailWrapper = document.createElement('div');
    detailWrapper.innerText = detail.options[detail.selectedIndex].innerText;
    locationDetailsWrapper.appendChild(detailWrapper);
  });

  var detailWrapper = document.createElement('div');
  detailWrapper.innerText = dateInput.value;
  locationDetailsWrapper.appendChild(detailWrapper);

  personalDetailList.forEach((detail) => {
    var detailWrapper = document.createElement('div');
    detailWrapper.innerText = detail.value;
    personalDetailsWrapper.appendChild(detailWrapper);
  })
}

function openDatepicker(e) {
  e.preventDefault();
  document.body.classList.add('datepicker-open');
  document.body.classList.remove('datepicker-closed');
}

function validateField(field) {
  var fieldValid = true;
  var parent = findAncestor(field, 'field-js');
  var error = parent.querySelector('.field-error-js');

  if (field.type === 'checkbox' ? !field.checked : field.value === '') {
    error.style.opacity = '1';
    fieldValid = false;
  } else {
    error.style.opacity = '0';
  }

  return fieldValid;
}

function validateStep() {
  var step = document.querySelector('.widget-slider-js').dataset.currentStep;
  var stepContainer = document.querySelector(`.widget-slide-js:nth-child(${step})`);
  var stepValid = true;
  if (stepContainer !== null) {
    var fields = stepContainer.querySelectorAll('.validate-me-js');

    fields.forEach((field) => {
      var fieldValid = validateField(field);
      if (!fieldValid) {
        stepValid = false;
      }
    });
  }

  return stepValid;
}

function processReservation(data) {
  if (data.B25__Total_Price__c > 0)
    API.instance
      .createPayment(data.Id, data.B25__Total_Price__c)
      .then((data) => window.location = data.url);
  else {
    confirmBooking();
  }
}

function createReservation() {
  var firstname = document.querySelector('.firstname-js').value;
  var lastname = document.querySelector('.lastname-js').value;
  var email = document.querySelector('.email-js').value;
  var phone = document.querySelector('.phone-js').value;
  var reservation_type = document.querySelector('.reservation-type-js');
  var company = document.querySelector('.company-js');


  var reservation_type_id = null;
  if (reservation_type)
    reservation_type_id = reservation_type.value;

  var company_name = null;
  if (company)
    company_name = company.value;

  API.instance.createReservation(
    selectedResourceId, reservation_type_id,
    firstname, lastname, email, phone, selectedReservation.start,
    selectedReservation.end, company_name, getCustomFieldsData()
  ).then(processReservation);
}

function navigateWidget(e, direction) {
  e.preventDefault();

  var stepValid = true;
  if (direction === 1) {
    stepValid = validateStep();
  }

  if (!stepValid) {
    return false;
  }

  if (direction === 1 && stepValid) {
    updateInfo();
  }

  var widget = document.querySelector('.widget-slider-js');
  var totalSteps = parseInt(widget.dataset.steps);
  var currentStep = parseInt(widget.dataset.currentStep);
  var change = parseInt(direction);
  var newStep = (currentStep + change <= totalSteps) ? currentStep + change : currentStep;
  var left = newStep * -100 + 100;

  widget.dataset.currentStep = newStep;
  widget.style.left = left + '%';

  if (currentStep + change > totalSteps) {
    createReservation();
  } else if (newStep === totalSteps) {
    document.querySelector('.next-text-js').style.opacity = '0';
    document.querySelector('.confirm-text-js').style.opacity = '1';
  } else {
    document.querySelector('.next-text-js').style.opacity = '1';
    document.querySelector('.confirm-text-js').style.opacity = '0';
  }

  if (newStep === 1) {
    document.querySelector('.prev-js').classList.remove('clickable');
  } else {
    document.querySelector('.prev-js').classList.add('clickable');
  }

  var crumbs = Array.from(document.querySelectorAll('.crumb-js'));
  crumbs.forEach((crumb) => {
    crumb.classList.remove('--active');
    crumb.classList.remove('--passed');
    var crumbStep = parseInt(crumb.getAttribute('data-step'));
    if (newStep === crumbStep) {
      crumb.classList.add('--active');
    } else if (newStep > crumbStep) {
      crumb.classList.add('--passed');
    }
  });
}

function setActive(element, reservation) {
  selectedReservation = reservation;
  var $element = $(element);
  $('.time.--active').removeClass('--active');
  $element.addClass('--active');

  var dayList = $element.parents('.available-day');
  var activeRow = $('.ui-state-active').parents('tr');
  activeRow.find('td').eq(dayList.index()).trigger('click');
  document.body.classList.add('time-selected');
}

function queryParams() {
  var queryString = window.location.search.slice(1);

  return queryString === "" ? {} : queryString
    .split('&')
    .reduce((params, param) => {
      var keyValue = param.split('=');

      var key = keyValue[0];
      params[key] = keyValue.length > 1 ? keyValue.splice('1').join('=') : true;

      return params;
    }, {});
}

window.addEventListener('load', () => {
  const lang_element = document.querySelector('.language-link-js.selected');
  const lang = lang_element ? lang_element.getAttribute('data-language') : undefined;

  Promise.all([
    API.instance.getResources(lang),
    API.instance.getFilterLevels(lang)
  ]).then((values) => {
    renderForm(...values)
  });

  if (queryParams().hasOwnProperty('success'))
    confirmBooking();

  var dateText;
  $('#weekpicker').weekpicker({
    currentText: dateText,
    onSelect: (dateText, startDateText, startDate, endDate, inst) => {
      if (datePickerStartDate === undefined
        || datePickerStartDate.valueOf() !== moment(startDate).valueOf()
        || previousResourceId !== selectedResourceId) {

        datePickerStartDate = moment(startDate);
        datePickerEndDate = moment(endDate);

        API.instance.getTimeslots(
          datePickerStartDate.format('YYYY-MM-DDT00:00:00'),
          datePickerEndDate.format('YYYY-MM-DDT23:59:59'),
          selectedResourceId,
        ).then((data) => {
          renderTimeSlots(data);

          document.body.classList.add('date-selected');
          if ($('.ui-state-active').parent().index() !== $('.time.--active').parent().index()) {
            $('.time.--active').removeClass('--active');
            document.body.classList.remove('time-selected');
          }
        });

        previousResourceId = selectedResourceId;
      }
    }
  });

  let languagePicker =
    document.querySelector('.change_language-js');

  languagePicker && languagePicker.addEventListener('change', ({target}) => {
    let params = queryParams();
    params['language'] = target.value;

    let queryString = Object
      .keys(params)
      .reduce((queryString, key, index) => {
        return `${queryString}${index > 0 ? '&' : ''}${key}=${params[key]}`;
      }, '?');

    window.location.replace(queryString);
  });
});
